<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>
    <section class="section is-main-section">

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Informasi
          </p>
        </header>

        <div class="card-content" style="padding-left: 16px; line-height: 32px;">
          <p>Nama  : <span v-if="whoami"> {{whoami.name}} </span></p>
          <p>NIM : <span v-if="whoami"> {{whoami.noid}} </span></p>
          <p>Jenjang : <span v-if="whoami">{{ whoami.jenjang}}</span></p>
          <p>Konsentrasi : <span v-if="whoami"> {{whoami.kbk}} </span></p>
          <p>Kelas : <span v-if="whoami"> {{ whoami.kelas}} </span></p>
          <p>No. Handphone : <span v-if="whoami">  {{ whoami.phone}} </span></p>
          <p>Email : <span v-if="whoami"> {{ whoami.email}} </span></p>
        </div>
      </div>

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Keuangan
          </p>
        </header>
        <div class="card-content">
          <b-table
            :checked-rows.sync="checkedRows"
            :loading="isLoading"
            :paginated="paginated"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            :data="pengajuan"
          >
            <b-table-column label="Tanggal" field="tanggal" sortable v-slot="props">
              {{ props.row.tahun }}
            </b-table-column>
            <b-table-column label="Semester Akademik" field="semester akademik" sortable v-slot="props">
              {{ props.row.semester }}
            </b-table-column>
            <b-table-column label="Status" field="status" sortable v-slot="props">
              {{ props.row.status}} <br> <b v-if="props.row.note2" >Catatan:</b> {{props.row.note2}}
            </b-table-column>
            <!-- <b-table-column label="Pembimbing1" field="Pembimbing1" sortable v-slot="props">
              {{ props.row.pembimbing1 }}
            </b-table-column>
            <b-table-column label="Pembimbing2" field="Pembimbing2" sortable v-slot="props">
              {{ props.row.pembimbing2 }}
            </b-table-column>
            <b-table-column label="Judul" field="judul" sortable v-slot="props">
              {{ props.row.judul }}
            </b-table-column>
            <b-table-column label="Proposal" field="proposal" sortable v-slot="props">
              {{ props.row.file }}
            </b-table-column>
            <b-table-column label="STUG" field="stug" sortable v-slot="props">
              {{ props.row.stug }}
            </b-table-column> -->
            <!-- <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
              <div class="buttons is-right">
                <router-link :to="{name:'thesis.show', params: {id: props.row.id}}" >
                  <b-button
                      label="Lihat"
                      type="is-success"
                      size="is-small"
                      icon-left="magnify" />
                </router-link>
              </div>
            </b-table-column> -->

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </div>

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Proposal
          </p>
        </header>
        <div class="card-content">
          <b-table
            :checked-rows.sync="checkedRows"
            :loading="isLoading"
            :paginated="paginated"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            :data="data"
          >
            <b-table-column label="Nama" field="name" sortable v-slot="props">
              {{ props.row.mahasiswa }}
            </b-table-column>
            <b-table-column label="NIM" field="nim" sortable v-slot="props">
              {{ props.row.nim }}
            </b-table-column>
            <b-table-column label="Tahun Akademik" field="tahun akademik" sortable v-slot="props">
              {{ props.row.tahun }}
            </b-table-column>
            <b-table-column label="Pembimbing1" field="Pembimbing1" sortable v-slot="props">
              {{ props.row.pembimbing1 }}
            </b-table-column>
            <b-table-column label="Pembimbing2" field="Pembimbing2" sortable v-slot="props">
              {{ props.row.pembimbing2 }}
            </b-table-column>
            <b-table-column label="Judul" field="judul" sortable v-slot="props">
              {{ props.row.judul }} <br> <b v-if="props.row.perbaikan_judul">Revisi Judul:</b> {{props.row.perbaikan_judul}}
            </b-table-column>
            <b-table-column label="Proposal" field="proposal" sortable v-slot="props">
              <a :href="props.row.file" target="_blank">unduh</a>
            </b-table-column>
            <b-table-column label="STUG" field="stug" sortable v-slot="props">
              <a :href="props.row.stug" target="_blank" v-if="props.row.stug">unduh</a>
            </b-table-column>
            <b-table-column label="Status" field="status" sortable v-slot="props">
              {{ props.row.status}} <br> <b v-if="props.row.note" >Catatan:</b> {{props.row.note}}
            </b-table-column>
            <!-- <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
              <div class="buttons is-right">
                <router-link :to="{name:'thesis.show', params: {id: props.row.id}}" >
                  <b-button
                      label="Lihat"
                      type="is-success"
                      size="is-small"
                      icon-left="magnify" />
                </router-link>
              </div>
            </b-table-column> -->

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
// import Tiles from '@/components/Tiles'
// import CardWidget from '@/components/CardWidget'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    // CardWidget,
    // Tiles,
    HeroBar,
    TitleBar
  },
  data () {
    return {
      oriData: [],
      data: [],
      searchWord: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: [],
      pengajuan: [],
      whoami: false
    }
  },
  computed: {
    titleStack () {
      return ['Dashboard']
    },
    currentRole () {
      console.log(this.$store.getters.currentRole)
      return this.$store.getters.currentRole
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.isLoading = true
      axios
        .get('/mahasiswa_proposals')
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            if (r.data.data.length > this.perPage) {
              this.paginated = true
            }
            this.data = r.data.data
            this.oriData = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/mahasiswa_payments')
        .then((r) => {
          this.pengajuan = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/whoami')
        .then((r) => {
          this.whoami = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
